<template src="./LeaveResearchGroup.html"></template>

<script>
import idiService from "@/services/Idi";

export default {
  name: "leave-research-group-modal",
  props: {
    openLeaveGroupModal: { type: Boolean, default: false },
    infoGroupLeave: { type: Object },
  },
  methods: {
    closeModal() {
      this.$emit("closeTheLeaveGroupModal");
    },
    async leaveTheGroup() {
      try {
        await idiService.deleteParticipant(this.infoGroupLeave);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Salio del grupo con éxito",
          type: "is-success",
        });
        this.closeModal();
        this.$router.push({
          name: "ViewAllResearchGroups",
          params: { title: "Grupos de investigación" },
        });
      } catch (e) {
        console.error(e);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: e.response.data.message ?? "Ocurrio un error",
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./LeaveResearchGroup.scss"></style>